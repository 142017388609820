import React, { useEffect, useState } from "react";
import "./Hangers.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import {
  buyStoreMembership,
  membershipUpgrade,
  membershipUpgradeReset,
} from "../components/StateSlices/membershipUpgradeSlice";
import {
  membershipCancel,
  membershipCancelReset,
  storeMembershipCancel,
} from "../components/StateSlices/membershipCancelSlice";
import { getmembershipHistory } from "../components/StateSlices/membershiphistory";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import { getOrders } from "../components/StateSlices/getOrderSlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "./Membership.css";
import InjectedCheckoutSection from "../components/CardSection/MembershipPayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  cardUpgrade,
  cardUpgradeReset,
} from "../components/StateSlices/cardUpgradeSlice";

import "react-toastify/dist/ReactToastify.css";
import OrderTable from "../components/OrderTable/OrderTable";
import Collapse, { Panel } from "rc-collapse";
import { Helmet } from "react-helmet";
import {
  resetcoupen,
  verifyCoupenCode,
} from "../components/StateSlices/verifyCoupenSlice";

const stripePromise = loadStripe("pk_live_AEfhNK2o4rBBoKqhTFWMQe2N");

// const customStyles = {
//   overlay:{
//     overflow:"auto"
//   },
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     width: "60%",
//   },
// };

const Membership = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(true);
  const [insideSpinner, setInsideSpinner] = useState(false);
  const {
    membershipUpgradeStatus,
    membershipUpgradeInfo,
    membershipUpgradeError,
  } = useSelector((state) => state.membership);
  const { cardUpgradeStatus, cardUpgradeInfo, cardUpgradeError } = useSelector(
    (state) => state.card
  );
  const {
    membershipHistoryStatus,
    membershipHistoryInfo,
    membershipHistoryError,
    withinMile,
  } = useSelector((state) => state.membershiphistory);
  const {
    membershipCancelStatus,
    membershipCancelInfo,
    membershipCancelError,
  } = useSelector((state) => state.membershipcancel);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { orderStatus, orderInfo } = useSelector((state) => state.orders);
  const { coupenStatus, coupenInfo, coupenError, coupenDetail } = useSelector(
    (state) => state.verifycoupen
  );
  const [membershipType, setMembershipType] = useState("monthly");
  const [message, setMessage] = useState("");
  const [cardUpdate, setCardUpdate] = useState(false);
  const [storeBuy, setStoreBuy] = useState(false);
  const [agreebox, setAgreebox] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [coupen, setCoupen] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [disableCoupen, setDisableCoupen] = useState(false);
  const [coupenApplied, setCoupenApplied] = useState(null);

  const [customStyles, setCustomStyles] = useState({
    overlay: {
      overflow: "auto",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%", // Default width
    },
  });

  // Adjust styles based on screen width
  useEffect(() => {
    const updateStyles = () => {
      const newWidth = window.innerWidth <= 768 ? "80%" : "60%";
      setCustomStyles((prevStyles) => ({
        ...prevStyles,
        content: { ...prevStyles.content, width: newWidth },
      }));
    };

    updateStyles(); // Set initial width
    window.addEventListener("resize", updateStyles); // Update on resize

    return () => window.removeEventListener("resize", updateStyles); // Cleanup
  }, []);

  const toastOption = {
    position: "top-right",
    //autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    let search = location.search;
    setSpinner(true);
    if (search) {
      let qry = new URLSearchParams(search);
      let error = qry.get("error");
      if (error === "membershipExpired") {
        if (userInfo.userDetailsId.membership == "trial") {
          setMessage(
            "You are currently on free subscription with limited access. Upgrade now to enjoy complete access"
          );
        } else {
          setMessage(
            "Your Membership has been expired. Please upgrade your membership to continue using our services."
          );
        }
      }
    }
    console.log(token);
    if (token) {
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 480);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("clttoken");
      dispatch(getmembershipHistory(token));
    }
  }, [userInfo]);

  useEffect(() => {
    if (membershipHistoryInfo) {
      setSpinner(
      );
    }
  }, [membershipHistoryInfo]);

  function openModal(updateCard = false) {
    // setBrandName("")
    setIsOpen(true);
    setStoreBuy(false);
    console.log(updateCard);
    if (updateCard) {
      setCardUpdate(true);
    } else {
      setCardUpdate(false);
    }
  }

  function openStoreModal(updateCard = false) {
    // setBrandName("")
    setIsOpen(true);
    // console.log(updateCard);
    setCardUpdate(false);
    setStoreBuy(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const membershipHandle = (token = null) => {
    console.log(token, userInfo.userDetailsId.paymentId);
    let payload = {
      token: token,
      paymentId: userInfo.userDetailsId.paymentId,
      userId: userInfo._id,
      membershipType: membershipType,
      coupen: coupen,
    };
    setSpinner(true);
    console.log("STOREBUY", storeBuy);
    if (storeBuy) {
      if (!agreebox) {
        toast.error("Please agree to the terms and conditions", toastOption);
        setSpinner(false);
        return;
      }
      dispatch(buyStoreMembership(payload));
    } else if (!cardUpdate) {
      dispatch(membershipUpgrade(payload));
    } else {
      dispatch(cardUpgrade(payload));
    }

    closeModal();
  };

  if (membershipUpgradeInfo) {
    setMessage("");
    toast.success("Membership Upgraded Successfully", toastOption);
    if (userInfo.userDetailsId.membership == "trial") {
      toast.success("+1  hanger Added!!", toastOption);
    }
    dispatch(membershipUpgradeReset());
    dispatch(membershipCancelReset());

    let token = localStorage.getItem("clttoken");
    console.log("Called");
    setTimeout(() => {
      dispatch(getuserInfo({ token: token }));
    }, 2000);
    setMembershipType("annualy");
    setDiscount(0);
    setDiscountPercent(0);
    setCoupen("");
    setCoupenApplied(null);
    setInsideSpinner(false);
    setDisableCoupen(false);
  }

  if (cardUpgradeInfo) {
    setMessage("");
    toast.success("Card Updated Successfully", toastOption);
    dispatch(membershipUpgradeReset());
    dispatch(membershipCancelReset());
    setCardUpdate(false);
    dispatch(cardUpgradeReset());
    setSpinner(false);
  }

  if (membershipCancelInfo) {
    toast.success("Membership Cancelled Successfully", toastOption);
    dispatch(membershipCancelReset());
    dispatch(membershipUpgradeReset());
    //setSpinner(false)
    //closeModal()
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token }));
    setMembershipType("annualy");
  }

  if (membershipUpgradeError) {
    toast.error(membershipUpgradeError, toastOption);
    dispatch(membershipUpgradeReset());
    setSpinner(false);
  }

  if (cardUpgradeError) {
    toast.error(cardUpgradeError, toastOption);
    dispatch(cardUpgradeReset());
    setSpinner(false);
  }

  if (membershipCancelError) {
    toast.error(membershipCancelError, toastOption);
    dispatch(membershipCancelReset());
    setSpinner(false);
  }

  const membershipCancelTrigger = (id) => {
    Swal.fire({
      title: "Cancel Membership",
      html: `Are you sure? ${
        userInfo.userDetailsId.storeSubscriptionStatus
          ? " This is will also cancel your Store Membership"
          : ""
      }
      <br/> <br/>
      <div style='text-align:left'>You will no longer be able to use your hanger credits earned along with the following benefits after the current billing period.</div>
      <br/>
      <div>
      <ul style='text-align:left;list-style:disc;list-style-type:disc'>
        <li> Unlimited transactions with no fee</li>
        <li> Earn hanger credits by posting items to your virtual closet</li>
        <li> Shop other members closets with the hanger credits earned</li>
        <li> Discounted price on hanger credits if your are short</li>
        <li> Exclusive access to Member's Only items for the first 30 days</li>
        <li> Friend referral program access:</li>
        <ul style='text-align:left;list-style:disc'>
            <li> 5 free credits for the member + $5 subscription credit off the next payment.</li>
            <li> Referrals get 5 bonus hanger credits.</li>
        </ul>
      </ul>
      </div>`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete.isConfirmed) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(membershipCancel(token));
      }
    });
  };

  const storemembershipCancelTrigger = (id) => {
    swal({
      title: "Cancel Store Membership",
      text: " This will remove your access from store",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(storeMembershipCancel(token));
      }
    });
  };

  const checkCoupen = (coupenCode) => {
    if (coupenCode) {
      setInsideSpinner(true);
      console.log(coupenCode);
      dispatch(verifyCoupenCode({ coupenCode }));
    } else {
      toast.error("Please Enter Coupon Code", toastOption);
    }
  };

  if (coupenInfo) {
    console.log(coupenInfo, coupenDetail);
    if (coupenInfo.amount_off) {
      if (
        (coupenDetail && coupenDetail.subscriptionType === "annual") ||
        coupenInfo.name.toLowerCase().indexOf("year") > -1
      ) {
        if (membershipType === "annualy") {
          setDiscount(coupenInfo.amount_off / 100);
          setDiscountPercent(0);
          toast.success("Coupon Code Applied", toastOption);
          setDisableCoupen(true);
          setCoupenApplied(coupenInfo.id);
        } else {
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      } else if (
        (coupenDetail && coupenDetail.subscriptionType === "monthly") ||
        coupenInfo.name.toLowerCase().indexOf("month") > -1
      ) {
        if (membershipType === "monthly") {
          setDiscount(coupenInfo.amount_off / 100);
          setDiscountPercent(0);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      }
    } else if (coupenInfo.percent_off) {
      if (
        (coupenDetail && coupenDetail.subscriptionType === "annual") ||
        coupenInfo.name.toLowerCase().indexOf("year") > -1
      ) {
        if (membershipType === "annualy") {
          setDiscount((160 * coupenInfo.percent_off) / 100);
          setDiscountPercent(coupenInfo.percent_off);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          console.log("FF");
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      } else if (
        (coupenDetail && coupenDetail.subscriptionType === "monthly") ||
        coupenInfo.name.toLowerCase().indexOf("month") > -1
      ) {
        if (membershipType === "monthly") {
          setDiscount((20 * coupenInfo.percent_off) / 100);
          setDiscountPercent(coupenInfo.percent_off);
          setDisableCoupen(true);
          toast.success("Coupon Code Applied", toastOption);
          setCoupenApplied(coupenInfo.id);
        } else {
          console.log("FF");
          setCoupen("");
          toast.error("Invalid Coupon Code for this Membership", toastOption);
        }
      }
    }
    // setDiscount(coupenInfo)

    dispatch(resetcoupen());
    setInsideSpinner(false);
  }

  if (coupenError) {
    toast.error(coupenError, toastOption);
    dispatch(resetcoupen());
    setDiscount(0);
    setDiscountPercent(0);
    setCoupen("");
    setCoupenApplied(null);
    setInsideSpinner(false);
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for managing the membership plan to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for managing the membership plan to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for managing the membership plan to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <div
        className="page-container"
        style={{ position: "relative", minHeight: "520px" }}
      >
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            className="close_container"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button
              className="btn dark-btn"
              style={{ padding: "8px" }}
              onClick={closeModal}
            >
              X
            </button>
          </div>
          {cardUpdate ? (
            <div className="membership_details">
              <h3>Update Card Details</h3>
            </div>
          ) : storeBuy ? (
            <div className="membership_details">
              <h3>
                Key Membership 🔑 For Closest Closet Store @ 46 S. La Grange Rd,
                La Grange, IL 60525
              </h3>
              <div className="membership_title">
                Key members are given a unique access code to enter Closest
                Closet outside of store hours. Yes, you read that right. With
                this unique access comes great responsibility. Prospective key
                members must agree to the following terms & conditions before
                being granted Key Member status and a personalized entry code.
                $20 / month. The key membership status will appear as a separate
                charge from your monthly/annual membership.
              </div>
              <br />
              Closest Closet Key Members:
              <div
                className="membership_feature"
                style={{ marginBottom: "1rem" }}
              >
                <ul
                  style={{
                    padding: "0rem 1rem",
                    lineHeight: 1.2,
                    listStyle: "circle",
                  }}
                >
                  <li>Do not share their unique access code</li>
                  <li>
                    Do not bring non-members into the store when using entry
                    code
                  </li>
                  <li>
                    Drop their items in a reusable bag that is clearly labeled
                    with the members name
                  </li>
                  <li>
                    Check out items by signing into their closestcloset.com
                    account from their phone and adding tagged items to their
                    cart and completing checkout before leaving the store.
                  </li>
                  <li>
                    Leaves the store in the same condition, or better than when
                    they arrived
                  </li>
                  <li>Encourages others to become a Closest Closet member</li>
                  <li>
                    Make small changes in daily habits that decrease negative
                    impact on the planet
                  </li>
                </ul>
              </div>
              <br />
              <div>
                <label
                  htmlFor="agreebox"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <input
                    type="checkbox"
                    name="agreement"
                    id="agreebox"
                    checked={agreebox}
                    onChange={() => {
                      setAgreebox(!agreebox);
                    }}
                  />{" "}
                  <span>I agree to the above terms and conditions</span>
                </label>
              </div>
              <br />
              <div style={{ fontWeight: "bold" }}>
                *Closest Closet reserves the right to remove key member status
                at any time for any key members that violate the above terms.
              </div>
              <br />
            </div>
          ) : (
            <>
              {insideSpinner ? (
                <div style={{ height: "100%" }}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className="membership_details">
                    <h3>
                      {userInfo &&
                      userInfo.userDetailsId &&
                      userInfo.userDetailsId.subscriptionStatus &&
                      new Date(
                        userInfo.userDetailsId.membershipExpiration
                      ).getTime() >= new Date().getTime()
                        ? "Upgrade to Annual Membership"
                        : "Become a Member"}
                    </h3>
                    <div className="membership_title">
                      Membership Fee:
                      <br />
                      {userInfo &&
                      userInfo.userDetailsId.subscriptionStatus &&
                      new Date(
                        userInfo.userDetailsId.membershipExpiration
                      ).getTime() >= new Date().getTime() ? (
                        " $160 Annualy"
                      ) : (
                        <>
                          {" "}
                          <input
                            type="radio"
                            name="membership_type"
                            value="$20"
                            onChange={(e) => {
                              setMembershipType("monthly");
                              setDiscount(0);
                              setDiscountPercent(0);
                              setCoupen("");
                              setCoupenApplied(null);
                              setInsideSpinner(false);
                              setDisableCoupen(false);
                            }}
                            defaultChecked={
                              membershipType === "monthly" ? true : false
                            }
                            style={{ margin: "1rem .2rem", cursor: "pointer" }}
                          />{" "}
                          <label
                            htmlFor="membership_type"
                            style={{ paddingRight: "1rem" }}
                          >
                            $20/Month
                          </label>
                          &nbsp;
                          <input
                            type="radio"
                            name="membership_type"
                            value="$160"
                            onChange={(e) => {
                              setMembershipType("annualy");
                              setDiscount(0);
                              setDiscountPercent(0);
                              setCoupen("");
                              setCoupenApplied(null);
                              setInsideSpinner(false);
                              setDisableCoupen(false);
                            }}
                            defaultChecked={
                              membershipType === "annualy" ? true : false
                            }
                            style={{ margin: "1rem .2rem ", cursor: "pointer" }}
                          />{" "}
                          <label htmlFor="membership_type">$160/Year</label>
                        </>
                      )}
                    </div>

                    <div
                      className="membership_feature"
                      style={{ marginBottom: "1rem" }}
                    >
                      <ul
                        style={{
                          padding: "0rem 1rem",
                          lineHeight: 1.2,
                          listStyle: "circle",
                        }}
                      >
                        <li>Unlimited transactions with no fee</li>
                        <li>
                          Earn hanger credits by posting items to your virtual
                          closet
                        </li>
                        <li>
                          Shop other members closets with the hanger credits
                          earned
                        </li>
                        <li>
                          Discounted price on hanger credits if your are short
                        </li>
                        <li>
                          Exclusive access to Member's Only items for the first
                          30 days
                        </li>
                        <li>Friend referral program access:</li>
                        <ul
                          style={{
                            padding: "0rem 1rem",
                            lineHeight: 1.2,
                            listStyle: "disc",
                          }}
                        >
                          <li>
                            5 free credits for the member + $5 subscription
                            credit off the next payment.
                          </li>
                          <li>Referrals get 5 bonus hanger credits.</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div className="price_container_member">
                    <h4 style={{ margin: ".5rem 0", fontWeight: 700 }}>
                      <span>
                        Total Pay: $
                        {(membershipType === "trial"
                          ? 0
                          : membershipType === "monthly"
                          ? 20 - (discount || 0)
                          : 160 - (discount || 0)
                        ).toFixed(2)}
                      </span>
                      {discount ? (
                        <span style={{ marginLeft: "1rem", fontWeight: 700, color: "green" }}>
                          <small>
                            Discount: $
                            {membershipType === "trial" ? 0 : discount}
                            {discountPercent
                              ? " (" + discountPercent + "% OFF)"
                              : ""}
                          </small>
                        </span>
                      ) : (
                        ""
                      )}
                    </h4>
                  </div>

                  <div className="coupen_container">
                    <div className="input_container">
                      <label for="email_address">
                        Gift Card/Discount Code:
                      </label>
                      <input
                        type="text"
                        id="coupen_code"
                        name="coupen"
                        onChange={(e) =>
                          setCoupen(e.target.value.toLocaleUpperCase())
                        }
                        value={coupen}
                        disabled={disableCoupen}
                      />
                    </div>
                    {!disableCoupen ? (
                      <button
                        type="button"
                        onClick={() => checkCoupen(coupen)}
                        className="btn dark-btn coupen_btn"
                        disabled={status === "loading" ? true : false}
                      >
                        {status === "loading"
                          ? "Please Wait"
                          : "Apply Gift Card"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setDisableCoupen(false);
                          setCoupen("");
                          setDiscountPercent(0);
                          setDiscount(0);
                          setCoupenApplied(null);
                        }}
                        className="btn dark-btn coupen_btn"
                        disabled={status === "loading" ? true : false}
                      >
                        {status === "loading"
                          ? "Please Wait"
                          : "Clear Gift Card"}
                      </button>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {cardUpdate ||
          storeBuy ||
          (userInfo &&
            userInfo.userDetailsId &&
            (userInfo.userDetailsId.membership === "trial" ||
              (userInfo.userDetailsId.membership === "monthly" &&
                membershipType === "annualy"))) ? (
            <form onSubmit={membershipHandle}>
              <Elements stripe={stripePromise}>
                <InjectedCheckoutSection
                  submitForm={membershipHandle}
                  cardUpdate={cardUpdate}
                  spinner={spinner}
                  setSpinner={setSpinner}
                />
              </Elements>
              {/* <button className='btn dark-btn' type="submit" >Upgrade Membership</button> */}
            </form>
          ) : (
            <button
              type="button"
              onClick={() => membershipHandle()}
              className="btn dark-btn"
              // disabled={!this.props.stripe || this.props.spinner === true}
              style={{
                margin: "20px 0px",
                fontWeight: "bold",
                fontFamily: "LATO",
                fontSize: "16px",
              }}
            >
              Renew Membership
            </button>
          )}
        </Modal>
        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
        {spinner ? (
          <div style={{ width: "100%", height: "520px" }}>
            <Spinner />
          </div>
        ) : (
          <>
            {message ? (
              <p
                className="paragraph_title"
                style={{ color: "red", fontSize: "18px" }}
              >
                {message}
              </p>
            ) : (
              ""
            )}
            {/* <br />
            <br />
            <br /> */}
            {/* <h1 className="section_title bold-text">My Membership</h1> */}
            <h1 className="section_title">Current Membership Info</h1>
            <div className="">
              <div className="hangers_container">
                <div
                  className="order_table_container"
                  style={{ overflowX: "auto" }}
                >
                  <table>
                    <tr>
                      <th>Membership Plan</th>
                      <th>Membership Fee</th>
                      <th>Next Billing Cycle</th>
                      <th>Current Status</th>
                      <th>Actions</th>
                    </tr>
                    <tr>
                      <td style={{ textTransform: "capitalize" }}>
                        {userInfo &&
                        userInfo.userDetailsId &&
                        userInfo.userDetailsId.membership === "trial"
                          ? "Guest"
                          : userInfo.userDetailsId.membership}
                      </td>
                      <td>
                        {userInfo &&
                        userInfo.userDetailsId &&
                        userInfo.userDetailsId.membership == "monthly"
                          ? "$20"
                          : userInfo.userDetailsId.membership === "trial"
                          ? "$0"
                          : "$160"}
                      </td>
                      <td>
                        {userInfo &&
                          userInfo.userDetailsId &&
                          (userInfo.userDetailsId.membershipExpiration
                            ? new Date(
                                userInfo.userDetailsId.membershipExpiration
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                            : userInfo.userDetailsId.membership == "monthly"
                            ? new Date(
                                new Date(
                                  userInfo.userDetailsId.createdAt
                                ).getTime() + 2592000000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                            : new Date(
                                new Date(
                                  userInfo.userDetailsId.createdAt
                                ).getTime() + 31536000000
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }))}
                      </td>
                      <td>
                        {userInfo &&
                        userInfo.userDetailsId &&
                        userInfo.userDetailsId.membership === "trial"
                          ? "Guest"
                          : userInfo.userDetailsId.subscriptionStatus &&
                            new Date(
                              userInfo.userDetailsId.membershipExpiration
                            ).getTime() >= new Date().getTime()
                          ? "Active"
                          : `Cancelled - Ends ${new Date(
                              userInfo.userDetailsId.membershipExpiration
                            ).toDateString()}`}
                      </td>
                      {userInfo &&
                      userInfo.userDetailsId &&
                      userInfo.userDetailsId.subscriptionStatus &&
                      new Date(
                        userInfo.userDetailsId.membershipExpiration
                      ).getTime() >= new Date().getTime() ? (
                        userInfo.userDetailsId.membership == "monthly" ? (
                          <td className="upgrade_membership">
                            <a
                              href="javascript:void(0);"
                              onClick={() => openModal(false)}
                            >
                              Upgrade to Annual
                            </a>
                            &nbsp;/&nbsp;
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                membershipCancelTrigger(userInfo._id)
                              }
                            >
                              Cancel Membership
                            </a>
                            &nbsp;/&nbsp;
                            <a
                              href="javascript:void(0);"
                              onClick={() => openModal(true)}
                            >
                              Update Card
                            </a>
                          </td>
                        ) : (
                          <td className="upgrade_membership">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                membershipCancelTrigger(userInfo._id)
                              }
                            >
                              Cancel Membership
                            </a>
                            &nbsp;/&nbsp;
                            <a
                              href="javascript:void(0);"
                              onClick={() => openModal(true)}
                            >
                              Update Card
                            </a>
                          </td>
                        )
                      ) : (
                        <td className="upgrade_membership">
                          <a
                            href="javascript:void(0);"
                            onClick={() => openModal(false)}
                          >
                            Become a Member
                          </a>
                        </td>
                      )}
                    </tr>
                    {userInfo &&
                    userInfo.userDetailsId &&
                    userInfo.userDetailsId.subscriptionStatus &&
                    withinMile ? (
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          {userInfo &&
                          userInfo.userDetailsId &&
                          userInfo.userDetailsId.membership === "trial"
                            ? "Store"
                            : "Store"}
                        </td>
                        <td>
                          {userInfo &&
                          userInfo.userDetailsId &&
                          userInfo.userDetailsId.membership == "monthly"
                            ? "$20"
                            : userInfo.userDetailsId.membership === "trial"
                            ? "$20"
                            : "$20"}
                        </td>
                        <td>
                          {userInfo &&
                            userInfo.userDetailsId &&
                            (userInfo.userDetailsId
                              .storeSubscriptionExpiration &&
                            userInfo.userDetailsId.storeSubscriptionId
                              ? new Date(
                                  userInfo.userDetailsId.storeSubscriptionExpiration
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              : "N/A")}
                        </td>
                        <td>
                          {userInfo &&
                          userInfo.userDetailsId &&
                          !userInfo.userDetailsId.storeSubscriptionId
                            ? "Not Purchased"
                            : userInfo.userDetailsId.storeSubscriptionStatus &&
                              new Date(
                                userInfo.userDetailsId.storeSubscriptionExpiration
                              ).getTime() >= new Date().getTime()
                            ? "Active"
                            : "Cancelled"}
                        </td>
                        {userInfo &&
                        userInfo.userDetailsId &&
                        userInfo.userDetailsId.storeSubscriptionStatus &&
                        new Date(
                          userInfo.userDetailsId.storeSubscriptionExpiration
                        ).getTime() >= new Date().getTime() ? (
                          <td className="upgrade_membership">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                storemembershipCancelTrigger(userInfo._id)
                              }
                            >
                              Cancel Membership
                            </a>
                          </td>
                        ) : (
                          <td className="upgrade_membership">
                            <a
                              href="javascript:void(0);"
                              onClick={() => openStoreModal(false)}
                            >
                              Buy Store Membership
                            </a>
                          </td>
                        )}
                      </tr>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </div>

              <div className="hangers_container" style={{ marginTop: "80px" }}>
                <h1 className="section_title">Membership History</h1>
                <div className="order_table_container">
                  <table>
                    <tr>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                    {membershipHistoryInfo &&
                      membershipHistoryInfo.length > 0 &&
                      membershipHistoryInfo.map((item, index) => (
                        <tr
                          key={index}
                          style={
                            item.type === "trial"
                              ? {}
                              : item.type === "Refund"
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          <td style={{ textTransform: "capitalize" }}>
                            {item.type === "trial" ? "Guest" : item.type}
                          </td>
                          <td>
                            {item.type === "trial"
                              ? ""
                              : item.type === "Refund"
                              ? "+"
                              : "-"}{" "}
                            &#36;{item.money}
                          </td>
                          <td>
                            {new Date(item.time).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Membership;
